@import "../setting/fonts.scss";

.auth-page {
  display: flex;
  flex-direction: column;
  align-items: center; // 수평 가운데 정렬
  justify-content: flex-start; // 수직 상단 정렬
  min-height: 100vh; // 화면 전체 높이 사용


  * {
    box-sizing: border-box;
  }

  body {
    background: #f6f5f7;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: "Montserrat", sans-serif;
    height: 100vh;
    margin: -20px 0 50px;
  }

  h1 {
    font-weight: bold;
    margin: 0;
  }

  h2 {
    text-align: center; // 텍스트 가운데 정렬
    color: #fff; // 색상 조정 (흰색)
    margin-bottom: 20px; // 아래 여백 추가
  }

  .container {
    display: flex;
    flex-direction: column; // 기본적으로 상하 배치
    width: 100%;
    max-width: 400px; // 최대 너비 설정
    background-color: #fff; // 흰색 배경
    border-radius: 10px; // 모서리 둥글게
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // 박스 그림자 추가
    overflow: hidden; // 내용이 넘치지 않도록
    padding: 20px;
  }

  .form-container {
    width: 100%;
    padding: 20px;
  }

  .social-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .social {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      margin: 0 10px;
      font-size: 24px;
      color: #333;
      background-color: #f0f0f0; // 연한 회색 배경
      border-radius: 50%; // 둥근 모서리
      transition: color 0.3s, background-color 0.3s;

      &:hover {
        color: #ff416c;
        background-color: #e0e0e0; // 호버 시 배경색 변경
      }
    }
  }

  input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .forgot-password-link,
  .signup-link,
  button {
    display: block; // 각 요소를 블록 레벨로 설정
    width: 100%; // 너비를 100%로 설정하여 줄 전체를 차지하게 함
    margin-top: 10px; // 위쪽 여백 추가
  }

  .signup-link {
    color: #ff416c; // 링크 색상
    text-decoration: none; // 밑줄 제거
    font-weight: bold; // 글자 두껍게
    text-align: center; // 가운데 정렬

    &:hover {
      text-decoration: underline; // 호버 시 밑줄 추가
    }
  }

  button {
    background-color: #ff416c;
    color: #fff; // 버튼 텍스트 색상
    border: none; // 테두리 제거
    padding: 10px 20px; // 패딩 설정 (두 배로 증가)
    font-size: 16px; // 폰트 크기 설정
    cursor: pointer; // 커서 설정
    border-radius: 30px; // 좌우 모서리를 둥글게 설정
    width: 100%; // 버튼이 한 줄을 전부 차지하도록 설정
    transition: background-color 0.3s;

    &:hover {
      background-color: #ff4b2b; // 호버 시 배경색 변경
    }
  }

  a {
    color: #333;
    text-decoration: none;
    font-size: 14px;
    margin-top: 10px;

    &:hover {
      text-decoration: underline;
    }
  }

  .google-signin-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4285F4; // Google Blue
    color: white;
    border: none;
    border-radius: 5px;
    padding: 0;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
    width: 65%;
    max-width: 300px;
    height: 40px;
    margin: 10px auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, box-shadow 0.3s;

    img {
      margin-right: 5px;
      width: 38px;
      height: 38px;
    }

    span {
      flex: 1;
      text-align: center;
      font-weight: bold;
    }

    &:hover {
      background-color: darken(#4285F4, 10%);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
  }

  .signin-link {
    display: block;
    margin: 10px 0; // 위아래 여백 추가
    color: #ff416c; // 링크 색상
    text-align: center; // 가운데 정렬
    text-decoration: none; // 밑줄 제거
    font-weight: bold; // 글자 두껍게

    &:hover {
      text-decoration: underline; // 호버 시 밑줄 추가
    }
  }

  // UserInfo 컴포넌트를 위한 스타일 추가
  .user-info-detail {
    font-size: 16px;
    color: #666;
    line-height: 1.5;
    margin-bottom: 10px;
  }
}
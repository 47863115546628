#header {
    position: fixed;
    left: 0;
    top: 0;
    width: 260px;
    height: 100%;
    overflow-y: auto;
    z-index: 10000;
    border-right: 1px solid var(--black100);
}
#main {
    max-width: 2000px;
    min-height: 100vh;
    margin: 0 auto;
    padding-left: 260px;
}
#footer {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: var(--black100);
    border-top: 1px solid var(--black100);
    padding: 20px;
    color: var(--white);
    font-size: 0.8rem;

    a {
        color: var(--blue); // 링크 색상 설정
        text-decoration: none; // 밑줄 제거

        &:hover {
            text-decoration: underline; // 마우스 오버 시 밑줄 표시
        }
    }
}

.footer-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    padding: 0 50px; // 좌우 패딩 추가
}

.company-info {
    flex: 1;
    text-align: right; // 오른쪽 정렬
    padding-right: 30px; // 우측 간격 추가
}

.legal-links {
    flex: 1;
    text-align: left; // 왼쪽 정렬
    padding-left: 30px; // 좌측 간격 추가
}

@media (max-width: 800px){
    #header {
        left: -260px;
        background-color: var(--black);
        transition: left 0.3s;

        h1 {
            position: fixed;
            left: 0;
            top: 0;
        }
        .header__menu {
            margin-top: 90px;
        };
        &.active {
            left: 0;
        }
    }
    #main {
        padding-top: 90px; // 상단 패딩 추가하여 내용이 검색창에 가려지지 않도록 조정
        padding-left: 0;
    }
    #footer {
        width: 100%;
        margin-left: 0;
        flex-direction: column;
        text-align: center;
        align-items: center; // 가운데 정렬을 위해 추가

        .company-info, .links {
            flex: none;
            text-align: center;
        }
    }
    .footer-container {
        flex-direction: column; // 요소들을 수직으로 쌓음
        padding: 0 20px; // 모바일 화면에 맞게 좌우 패딩 조정
    }

    .company-info,
    .legal-links {
        flex: none; // flex 설정 제거
        text-align: center; // 텍스트를 중앙 정렬
        padding: 10px 0; // 상하 패딩 추가
    }
}



.today__inner {
    display: flex;
    background-color: var(--black100);
    margin-bottom: 3.125rem;
}

.today__thumb {
    width: 40%;
}

.today__text {
    width: 60%;
    padding: 2rem;

    .today {
        background-color: #f00;
        padding: 2px 50px;
        border-radius: 20px;
        text-transform: uppercase;
        margin-bottom: 10px;
        display: inline-block;
    }
    .title {
        font-size: 2rem;
        margin-bottom: 1rem;
        @include line-one;
    }
    .desc {
        margin-bottom: 1rem;
        color: var(--white200);
        @include line(4)
    }
        
    .info {
        .author {
            font-size: 1rem;
            margin-right: 10px;
            font-weight: 900;
            text-decoration: underline;
            text-underline-position: under;
            text-transform: uppercase;
        }
        .date {
            font-style: 0.8rem;
            color: var(--white200);
        }
    }
} 

@media (max-width: 1700px){
    .today__inner {
        .today__thumb {
            width: 500px;
        }
        .today__text {
            width: calc(100% - 500px);

            .desc {
                @include line(2);
            }
        }
    }
}

@media (max-width: 1200px){
    .today__inner {
        flex-direction: column;

        .today__thumb {
            width: 100%;
        }
        .today__text {
            width: 100%;

            .title {
                font-size: 24px;
            }

            .desc {
                display: none;
            }
        }
    }
}

@media (max-width: 800px){
    #todayPage,
    #today {
        margin-top: 80px;
    }
}

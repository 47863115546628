.developer__inner {
    display: flex;
    flex-wrap: wrap;

    &.overflow {
        width: 100%;
        height: 200px;
        overflow: hidden;
    }

    .developer {
        // margin-right: 20px;

        .developer__img {
            // width: 150px;
            // height: 150px;
            border-radius: 50%;
            overflow: hidden;
            margin-bottom: 10px;
        }
        .developer__info {
            // width: 150px;
            text-align: center;
            @include line-one;
            // padding-bottom: 40px;
        }
    }
}


#developerPage {
    .developer__inner {
        .developer {
            margin-right: 20px;
        }
        .developer__img {
            width: 150px;
            height: 150px;
        }
        .developer__info {
            width: 150px;
            padding-bottom: 40px;
        }
    }
}

@media (max-width: 1200px){
    #developerPage {
        .developer__inner {
            justify-content: center;
    
            .developer {
                margin-right: 0;
                
                .developer__img {
                    width: 130px;
                    height: 130px;
                    margin: 10px auto;
                }
            }   
        }
    }
    
}
@media (max-width: 800px){
    #developerPage {
        .developer__inner {
            margin-top: 70px;
        }
    }
}
// Path: src/assets/scss/section/_developer.scss
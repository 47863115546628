@font-face {
    font-family: "nexonLv1";
    font-weight: 100;
    font-style: normal;
    src: url("../../fonts/nexonLv1-Light.woff2") format("woff2"),
         url("../../fonts/nexonLv1-Light.woff") format("woff");
    font-display: swap;
}
@font-face {
    font-family: "nexonLv1";
    font-weight: 400;
    font-style: normal;
    src: url("../../fonts/nexonLv1-Regular.woff2") format("woff2"),
         url("../../fonts/nexonLv1-Regular.woff") format("woff");
    font-display: swap;
} 
@font-face {
    font-family: "nexonLv1";
    font-weight: 700;
    font-style: normal;
    src: url("../../fonts/nexonLv1-Bold.woff2") format("woff2"),
         url("../../fonts/nexonLv1-Bold.woff") format("woff");
    font-display: swap;
} 
@font-face {
    font-family: "nanumSquareNeo";
    font-weight: 300;
    font-style: normal;
    src: url("../../fonts/nanumSquareNeo-Light.woff2") format("woff2"),
         url("../../fonts/nanumSquareNeo-Light.woff") format("woff");
    font-display: swap;
}
@font-face {
    font-family: "nanumSquareNeo";
    font-weight: 400;
    font-style: normal;
    src: url("../../fonts/nanumSquareNeo-Regular.woff2") format("woff2"),
         url("../../fonts/nanumSquareNeo-Regular.woff") format("woff");
    font-display: swap;
}
@font-face {
    font-family: "nanumSquareNeo";
    font-weight: 700;
    font-style: normal;
    src: url("../../fonts/nanumSquareNeo-Bold.woff2") format("woff2"),
         url("../../fonts/nanumSquareNeo-Bold.woff") format("woff");
    font-display: swap;
}
@font-face {
    font-family: "nanumSquareNeo";
    font-weight: 800;
    font-style: normal;
    src: url("../../fonts/nanumSquareNeo-ExtraBold.woff2") format("woff2"),
         url("../../fonts/nanumSquareNeo-ExtraBold.woff") format("woff");
    font-display: swap;
}
@font-face {
    font-family: "nanumSquareNeo";
    font-weight: 900;
    font-style: normal;
    src: url("../../fonts/nanumSquareNeo-Black.woff2") format("woff2"),
         url("../../fonts/nanumSquareNeo-Black.woff") format("woff");
    font-display: swap;
}

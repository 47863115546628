.header__logo {
    border-bottom: 1px solid var(--black100);

    @media (max-width: 800px){
        display: none; // 모바일에서 로고 숨김
    }

    a {
        display: flex;
        padding: 10px;
        margin: 10px;

        img {
            max-width: 100%;
            height: auto;
        }
        em {
            width: 40px;
            height: 40px;
            display: block;
            background-color: var(--red);
            margin-right: 10px;
            animation: ani 20s 20s ease-in-out infinite;
        }

        span {
            font-size: 20px;
            line-height: 1;
            font-weight: 900;
            text-transform: uppercase;
        }
    }
}

.header__menu-icon {
    display: none; // 기본적으로 숨김

    @media (max-width: 800px) {
        display: block; // 모바일에서만 보임
        position: absolute;
        left: 10px; // 적절한 위치 조정
        top: 10px;
        font-size: 30px; // 아이콘 크기 조정
        color: var(--white); // 아이콘 색상
    }
}

.header__menu {
    padding: 10px 0;

    .menu {
        border-bottom: 1px solid var(--black100);

        li {
            margin-bottom: 5px;

            a {
                font-size: 1rem;
                display: block;
                padding: 0.9rem 2rem 0.9rem 3.125rem;
                margin: 0 0.625rem;
                border-radius: 40px;
                position: relative;
                line-height: 1;
                color: var(--white200);
                transition: all 0.3s;

                svg {
                    width: 1.25rem;
                    height: 1.25rem;
                    position: absolute;
                    left: 1.25rem;
                    top: 0.75rem;
                }

                &:hover {
                    background-color: var(--black100);
                    color: var(--white);
                }
            }

            &.active a {
                background-color: var(--black100);
                color: var(--white);
            }
        }
    }

    .keyword {
        padding: 1.25rem;

        li {
            display: inline-block;

            a {
                display: inline-block;
                padding: 5px 15px;
                border: 1px solid var(--black200);
                font-size: 0.8rem;
                border-radius: 40px;
                margin: 3px 1px;
                transition: all 0.3s;

                &:hover {
                    background-color: var(--black100);
                    color: var(--white);
                }
            }

            &.active a {
                background-color: var(--black100);
                color: var(--white);
            }
        }
    }
}

.header__sns {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 260px;
    text-align: center;
    padding: 15px 0 10px;
    background-color: var(--blackAlpha100);
    backdrop-filter: blur(10px);

    @media (max-width: 800px){
        display: none;
    }

    li {
        display: inline-block;

        a {
            padding: 5px 7px;
            display: inline-block;
        }
    }
}

@keyframes ani {
    0% {
        transform: rotate(0) scale(1);
        border-radius: 0;
    }
    50% {
        transform: rotate(720deg) scale(0.5);
        border-radius: 20px;
    }
    100% {
        transform: rotate(0) scale(1);
        border-radius: 0;
    }
}

.header__logo {
    display: flex;
    align-items: center;
    justify-content: center;

    .logo-image, .logo-text {
        display: block; // 기본적으로 로고 이미지와 텍스트를 보여줌
    }

    .menu-icon {
        display: none; // 기본적으로 메뉴 아이콘을 숨김
    }

    @media (max-width: 800px) {
        .logo-image, .logo-text {
            display: none; // 모바일에서 로고 이미지와 텍스트를 숨김
        }

        .menu-icon {
            display: block; // 모바일에서 메뉴 아이콘을 보여줌
            font-size: 24px; // 아이콘 크기 조정
            color: var(--white); // 아이콘 색상
        }
    }
}


.video-summary-container {
    display: flex;
    flex-direction: row; // 컨테이너를 가로로 배열
    width: 100%;
    height: 100vh; // 전체 화면을 사용
    overflow: hidden; // 스크롤을 없애기 위해 오버플로우 숨김
}

.video-container {
    flex: 2; // 전체의 2/3 차지
    display: flex;
    flex-direction: column; // 내부 요소를 세로로 배열
    position: relative;
    width: 100%;
    height: 85%; // 부모 컨테이너의 높이를 채우도록 설정
}

.thumbnail {
    flex: 9; // 전체의 9/10 차지
    background-size: cover;
    background-position: center;
    cursor: pointer;
    width: 100%;
    height: 85%;
}

.video-title {
    flex: 1;
    width: 100%;
    color: white;
    text-align: center;
    font-size: 1.5em;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px 10px;
    border-radius: 5px;
    display: block; // 기본적으로 보이도록 설정
}

.summary-container {
    flex: 1;
    flex-direction: column; // 내부 요소를 세로로 배열
    width: 100%;
    height: 85%;
    // padding: 20px;
    box-sizing: border-box;
    display: flex;
}

.controls {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: #333;
}

.summary-select {
    background-color: #fff; // 흰색 배경
    color: #000; // 검정 글자
    border: 1px solid #ccc; // 경계선 추가
    padding: 4px 12px; // 패딩 줄임
    margin-right: 10px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    appearance: auto; // 기본 브라우저 스타일 복원

    &:focus {
        outline: none;
        box-shadow: 0 0 5px rgba(81, 203, 238, 1);
    }

    &:hover {
        background-color: #eee;
    }
}

.summary-button {
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;

    &:hover {
        background-color: #0056b3;
    }
}

.summary-content {
    flex: 9;
    overflow-y: auto; // 인라인 스크롤 기능
    padding: 20px;
    background-color: #222;
    color: white;
    box-sizing: border-box;
}


// 미디어 쿼리를 통한 모바일 화면 정의
@media (max-width: 768px) {
    .video-summary-container {
        flex-direction: column; // 세로로 배열
        padding-top: 70px; // 검색창이나 메뉴를 피하기 위해 상단 패딩 추가
    }

    .video-container,
    .summary-container {
        height: 50%; // 높이 조정
        width: 100%; // 너비를 100%로 설정
        margin-bottom: 10px; // 하단 마진 추가
    }
    
    .video-container {
        margin-bottom: 20px; // 비디오 컨테이너 하단에 마진 추가
    }
    
    .summary-container {
        padding-top: 20px; // 요약 컨테이너 상단에 패딩 추가
    }
}

:root {
    --mainfont-nanum: "nanumSquareNeo";
    --mainfont-nexon: "nexonLv1";
  
    --black: #000;
    --black100: #000;
    --black200: #000;
    --black300: #000;
    --black400: #000;
    --black500: #000;
    
    --white: #fff;
    --white100: #fff;
    --white200: #fff;
    --white300: #fff;
    --white400: #fff;
    --white500: #fff;
    
    // 폰트 셋팅
    font-family: var(--mainfont-nanum), var(--mainfont-nexon);
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
    
    // 폰트 최적화
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
  
    @media (max-width: 800px) {
        font-size: 14px;
        line-height: 1.4;
    }
}

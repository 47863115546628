.video__inner {
    display: grid;
    grid-template-columns: repeat(4, 24%);
    justify-content: space-between;

    @media (max-width: 1400px){
        grid-template-columns: repeat(3, 32%);
        gap: 15px;
    }
    @media (max-width: 1200px){
        grid-template-columns: repeat(2, 49%);
        gap: 10px;
    }
    @media (max-width: 400px){
        grid-template-columns: repeat(1, 100%);
    }

    .video {
        // margin-bottom: 10px;
        position: relative;

        .video__thumb {
            position: relative;

            &:after {
                content: attr(data-duration);
                position: absolute;
                right: 10px;
                bottom: 10px;
                background-color: rgba(0, 0, 0, 0.7);
                color: #fff;
                padding: 2px 5px;
                font-size: 0.75rem;
                border-radius: 3px;
            }
        }
    }
}
.video__inner.search {
    min-height: 100vh;
    
    .video {
        // margin-bottom: 30px;
        border-radius: 10px;
        background-color: #111;

        @media (max-width: 600px){
            margin-bottom: 10px;
        }

        .video__thumb {

            a {
                position: relative;
                display: block;
                cursor: pointer;
                width: 100%;
                padding-bottom: 70%;
                position: relative;
                background-size: cover;
                background-position: center;
            }
        }

        .video__info {
            padding: 10px 15px;
            color: #fff;
    
            .title {
                font-size: 1rem;
                @include line-two;
            }
            .info {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                
                .author {
                    @include line-one;
                    color: #666;
                    transition: all 0.3s;
    
                    &:hover {
                        color: #fff;
                    }
                }
                .date {
                    font-size: 14px;
                    color: #666;
                }
                .duration {
                    color: #fff;
                    font-size: 0.8rem;
                    margin-left: 10px;
                }
                
            }
            .summary-button {
                margin-top: 10px;
                padding: 5px 10px;
                background-color: #007bff;
                color: white;
                border: none;
                border-radius: 5px;
                cursor: pointer;
            }
            
            .summary-button a {
                color: white;
                text-decoration: none;
            }
        }
    }
}
.video__more {
    button {
        width: 100%;
        border: 0;
        padding: 20px;
        background-color: var(--black100);
        color: var(--white);
        transition: all 0.3s;
        margin-bottom: 20px;
        cursor: pointer;
        border-radius: 40px;

        &:hover {
            background-color: var(--black200);
        }
    }
}
// 비디오 페이지 
#videoViewPage {
    padding: 0;

    @media (max-width: 800px){
        margin-top: 80px;
    }
}
.video__view {

    .video__play {
        width: 100%;
        padding-bottom: 56.2%;
        position: relative;
    }

    .video__info {
        padding: 40px;

        .video__channel {
            display: flex;
            justify-content: space-between;
            margin-bottom: 40px;
            background-color: #111;
            padding: 10px 30px;
            border-radius: 40px;

            .id {
                font-weight: 800;
            }

            .count {

                span {
                    border-radius: 40px;
                    font-size: 14px;
                    margin-left: 10px;

                    svg {
                        width: 20px;
                        height: 20px;
                        vertical-align: -5px;
                        margin-right: 5px;
                    }
                }
            }
        }
        .video__desc {
            white-space: pre-line;
        }
    }

}

// 한줄 효과 
@mixin line-one {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

// 두줄 효과
@mixin line-two {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

// 두줄 이상
@mixin line($num){
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $num;
    -webkit-box-orient: vertical;
}

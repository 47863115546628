.privacy-policy {
  background-color: black; // 배경색을 검정색으로 설정
  color: white; // 전체 글자색을 흰색으로 변경

  .privacy-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .privacy-header {
    background-color: #333; // 헤더 배경색을 어두운 회색으로 변경
    padding: 20px 0;
    border-bottom: 1px solid #444; // 헤더 하단 테두리 색 변경
  }
  
  h1 {
    color: white; // 제목 색상 변경
    text-align: center;
  }
  
  main {
    padding: 20px 0;
  }
  
  section {
    margin-bottom: 20px;
  }
  
  h2 {
    color: #ddd; // 소제목 색상 변경
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    color: #ccc; // 목록 글자색 변경
    padding: 5px 0;
  }
  
  footer {
    background-color: #222; // 푸터 배경색 변경
    text-align: center;
    padding: 10px 0;
    margin-top: 20px;
  }
  
  p {
    color: #aaa; // 문단 글자색 변경
  }
}

.search__inner {
    position: relative;
    width: 100%;
    height: 80px;
    padding: 17px 40px;
    border-bottom: 1px solid var(--black100);
    display: flex;
    align-items: center;
    justify-content: space-between; // 검색 입력과 로그인 버튼 간 간격 조정

    label{
        width: 24px;
        height: 24px;
        background-image: url(../../img/icon/search.svg);
        position: absolute;
        left: 53px;
        top: 28px;
        background-size: cover;
    }

    input {
        background-color: var(--black100);
        border: 1px solid var(--black200);
        width: calc(100% - 50px); // 로그인 버튼 공간 확보
        padding: 14px 45px;
        border-radius: 40px;
        font-size: 16px;
        color: var(--white);
    }

    .login-button {
        background: none;
        border: none;
        color: white; // 버튼 색상
        font-size: 1.5em; // 아이콘 크기 조정
        position: relative; /* dropdown-menu positioning의 기준점 */
        display: inline-block; /* 필요한 경우 인라인 블록으로 설정 */
    }

    .dropdown-menu {
        position: absolute;
        top: 80%; /* 버튼의 바로 아래 위치 */
        right: 3%;
        background-color: white; /* 배경색을 흰색으로 설정 */
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2); /* 드롭다운 메뉴에 그림자 추가 */
        z-index: 1; /* 다른 요소들 위에 표시 */
    }
    
    .dropdown-menu ul {
        list-style: none; /* 기본 리스트 스타일 제거 */
        margin: 0;
        padding: 0;
    }
    
    .dropdown-menu li {
        padding: 8px 16px; /* 패딩 추가 */
        cursor: pointer; /* 마우스 오버 시 커서 변경 */
        color: black; /* 글자색을 검정색으로 설정 */
    }
    
    .dropdown-menu li:hover {
        background-color: #f1f1f1; /* 호버 시 배경색 변경 */
    }
}


@media (max-width: 800px){
    .search__inner {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 1000;
        background-color: var(--blackAlpha400);
        backdrop-filter: blur(10px);
        padding: 17px 20px 17px 20px; // 모바일 화면에서 패딩 조정

        label {
            left: 20px; // 모바일 화면에서 라벨 위치 조정
        }
        input {
            padding: 14px 14px 14px 45px; // 모바일 화면에서 입력창 패딩 조정
            width: calc(100% - 70px); // 로그인 버튼 공간 확보
            position: relative; // 입력창의 치를 상대적으로 설정
            left: 30px; // 입력창을 오른쪽으로 10px 이동
        }
        .login-button {
            margin-left: 5px; // 로그인 버튼 마진 조정
        }
    }
}
